// Constants.js

const dev = {
  API_URL: `https://excel-test.dataslayer.ai`,
  EXCEL_DATASLAYER_URL: "https://test-excel-addin.dataslayer.ai",
  DATASLAYER_URL: "https://test.dataslayer.ai/",
  CLIENT_ID: "2bb6338d-6340-4165-a17e-6d24bab35836",
  M_AUTHORITY: "https://login.microsoftonline.com/common",
  SERVICE_ID: "10",
};

const prod = {
  API_URL: `https://excel.dataslayer.ai`,
  EXCEL_DATASLAYER_URL: "https://excel-addin.dataslayer.ai",
  DATASLAYER_URL: "http://app.dataslayer.ai/",
  CLIENT_ID: "2bb6338d-6340-4165-a17e-6d24bab35836",
  M_AUTHORITY: "https://login.microsoftonline.com/common",
  SERVICE_ID: "10",
};

export const config = prod;
